<script>
import "@vueform/multiselect/themes/default.css";
import "flatpickr/dist/flatpickr.css";

import AuthService from "@/services/auth-service";

export default {
  components: {
  },
  page: {
  },
  data() {
  },
  async beforeMount() {
    let query = new URLSearchParams(this.$route.query).toString()

    const response = await AuthService.googleCallback(query)
    await this.$store.dispatch("auth/googleLogin", response.data.access_token);
    this.$router.push('/dashboard');
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
};
</script>

<template>
  <div>
<!--    <h1>Google Auth</h1>-->
  </div>
</template>
